import React from "react";

export function isBigInt(value: any) {
  return Object.prototype.toString.call(value).substring(8, 14) === 'BigInt';
}

export function debounce(func: Function, delay: number) {
  let timeout = -1;
  return function (...args: any) {
    //@ts-ignore
    const context = this;
    clearTimeout(timeout); // Clear the previous timer
    timeout = setTimeout(() => func.apply(context, args), delay); // Set a new timer
  };
}

export const createGameLink = (gameId: string) => {
  return React.createElement(
    'div',
    null,
    '',
    React.createElement(
      'a',
      { href: `/game/${gameId}`, target: '_blank', rel: 'noopener noreferrer' },
      `Game ${gameId}`
    )
  );
};

export const createGameLink2 = (gameId: string, playerAddress: string) => {
  return React.createElement(
    'div',
    null,
    `Player ${playerAddress} has joined your game `,
    React.createElement(
      'a',
      { href: `/game/${gameId}`, target: '_blank', rel: 'noopener noreferrer' },
      `Game ${gameId}`
    )
  );
};

export const getEndOfWeekInfo = (): {
  formattedEndOfWeek: string;
  timeLeftString: string;
} => {
  const now = new Date();
  const endOfWeek = new Date(now);

  const dayOfWeek = endOfWeek.getDay();
  const daysToSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

  endOfWeek.setDate(endOfWeek.getDate() + daysToSunday);
  endOfWeek.setHours(23, 59, 59, 999);

  const diffMs = endOfWeek.getTime() - now.getTime();

  const diffHours = diffMs / (1000 * 60 * 60);
  const diffDays = Math.floor(diffHours / 24);
  // const remainingHours = Math.floor(diffHours % 24);

  let timeLeftString: string;

  if (diffDays >= 1) {
    timeLeftString = `${diffDays} day${diffDays > 1 ? 's' : ''} from now`;
  } else {
    const hours = Math.ceil(diffHours);
    timeLeftString = `${hours} hour${hours > 1 ? 's' : ''} from now`;
  }

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[endOfWeek.getMonth()];
  const dayNum = endOfWeek.getDate();
  const year = endOfWeek.getFullYear();
  const formattedEndOfWeek = `${month} ${dayNum}, ${year}`;

  return { formattedEndOfWeek, timeLeftString };
};