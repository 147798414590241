export function formatSeconds(seconds: number) {
  const hrs = Math.floor(seconds / 3600); // Get hours
  const mins = Math.floor((seconds % 3600) / 60); // Get minutes
  const secs = seconds % 60; // Get seconds

  // Format to two digits (e.g., "08" instead of "8")
  const formattedHrs = String(hrs).padStart(2, '0');
  const formattedMins = String(mins).padStart(2, '0');
  const formattedSecs = String(secs).padStart(2, '0');

  return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
}

export function formatDate(date: bigint) {
  console.log('formatDate', date)
  return (new Date(+date.toString() * 1e3)).toUTCString();
}