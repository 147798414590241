import { Button, IconButton, Tooltip } from '@material-ui/core';
import HexAvatar from 'components/Avatar';
import { GameBoardPreview } from 'components/GameBoardPreview';
import { GamesTable } from 'components/GamesTable';
import { Loader } from 'components/Loader';
import NotFound from 'components/NotFound';
import { SvgIcon } from 'components/SvgIcon';
import Table from 'components/Table';
import WeeklyDialog from 'components/WeeklyLeaderboard';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { GamesTableModel } from 'models/GamesTableModel';
import { useEffect } from 'react';
import { FaInfoCircle, FaPencilAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Clock from '../../assets/imgs/clock.png';
import Count from '../../assets/imgs/count.png';
import Score from '../../assets/imgs/score.png';
import Win from '../../assets/imgs/win.png';
import "./style.scss";

type RouteParams = {
  address: string;
};

export const Profile = observer(() => {
  const { address } = useParams<RouteParams>();
  const { membersStore, profileStore, leaderboardStore } = useStore();
  const store = useStore();

  useEffect(() => {
    if (address) {
      membersStore.currentMemberAddress = address;
    }
    return () => {
      membersStore.currentMemberAddress = undefined;
    }
  }, [address])
  if (profileStore.profileIsLoading) return <Loader show />;
  const member = membersStore.memberProfile;
  if (!member) return <NotFound />;
  const { nickname, currentGames, completedGames, statsTableData } = member;
  console.log('currentGames', currentGames.map(it => it.hasWinner));

  const completedGamesTM = new GamesTableModel(completedGames);
  const columns = [
    {
      header: (
        <>
          <img src={Clock} alt="timing" width={20}/>
          <p>Time spent</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <img src={Count} alt="count" width={20}/>
          <p>Games count</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <img src={Win} alt="win" width={18}/>
          <p>Win percentage</p>
        </>
      ),
      flex: 2,
    },
    {
      header: (
        <>
          <span className="wld-container">
            <span className="wld-container-win">W</span>/
            <span className="wld-container-lose">L</span>/
            <span className="wld-container-draw">D</span>
          </span>
          <Tooltip title="Wins / Losses / Draws">
            <IconButton size="small" style={{ filter: 'none' }} className="info">
              <FaInfoCircle fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
      flex: 1,
    },
    {
      header: (
        <>
          <img src={Score} alt="score" width={18}/>
          <p>Score</p>
        </>
      ),
      flex: 1,
    },
    
  ];

  return (
    <div className='main-container profile'>
      <div className='body'>
      
        <section className='actions profile'>
          <div className="group-btns">
            <Button className='btn-back' onClick={() => store.navigate('/')}>⬅︎</Button>
          </div>
          <section className='details details-header'>
            <div className='details-header-container'>
              <div className='section-details-info'>
                {!nickname && profileStore.showSetNicknameButton ? (
                  <div className='section-details-info-nickname'>
                    <Button onClick={profileStore.openModal} className="btn-profile">
                    Click here to set nickname...
                    </Button>
                  </div>

                ) : (
                  <div className='section-details-info-nickname'>
                    <span>{nickname}</span>
                    {profileStore.showSetNicknameButton && (
                      <IconButton
                        onClick={profileStore.openModal} 
                        size="small" 
                        style={{ filter: 'none' }} 
                        className="info">
                        <FaPencilAlt fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                )}
                <div className='section-details-info-address'>{address}</div>
              </div>
              <div className='section-details-avatar'>
                <HexAvatar address={address} width={65} height={65} />
              </div>
            <div/>
          </div>
          <div className='profile-card-user-info'>
            <Table data={[
              {
                timeSpent: (
                  <div>
                    {statsTableData.timeSpent}
                  </div>
                ),
                gamesCount: (
                  <div>
                    {statsTableData.gamesCount}
                  </div>
                ),
                winPercentage: (
                  <div>
                    {statsTableData.winPercentage}%
                  </div>
                ),
                wld: (
                  <>
                    <span>{statsTableData.wins}</span>/
                    <span>{statsTableData.loses}</span>/
                    <span>{statsTableData.draws}</span>
                  </>
                ),
                Score: statsTableData.score,
              }
            ].filter(_ => Boolean)} columns={columns} />
            <div className='weekly-rating' onClick={leaderboardStore.openWeeklyModal}>
              <div className='weekly-rating-title'>Weekly score</div>
              <div className='weekly-rating-logo'>
                <SvgIcon name="trophy2" />
              </div>
              <div className='weekly-rating-data'>
                <SvgIcon classNames='trophy-small' name="trophy" width={20} height={20} />
                <div className='weekly-rating-data-points'>{profileStore.getWeeklyStats(address)?.score}</div>
                <div className='weekly-rating-data-count'>#{profileStore.getWeeklyRanking(address)}</div>
              </div>
            </div>
          </div>
        </section>
        </section>

        <section className='games-list'>
          <div className='content'>
            <div className='title'><h2>Games in progress</h2><span className='tiny-counter'>({currentGames.length})</span></div>
              <section className='games-completed'>     
                {currentGames.length > 0 ? (
                  <div className='games-current'>
                    {currentGames.map(game => (
                      <GameBoardPreview key={game.id} gameId={game.id} preview />
                    ))}
                  </div>
                ) : (
                  <p className="container empty-state">No games in progress</p>
                )}
              </section>
          </div>
          <div className='content'>
            <div className='title'><h2>Completed Games</h2><span className='tiny-counter'>({completedGames.length})</span></div>
            <section className='games-completed'>
                <GamesTable gamesModel={completedGamesTM} preview emptyMessage="No completed games"/>
              </section>
          </div>
        </section>
      </div>
      <WeeklyDialog />
    </div>
  );
});