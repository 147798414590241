import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField
} from "@material-ui/core";
import CustomButton from "components/CustomButton";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { FormCol, FormRow } from "pages/Game/components/NewGameDialog/styled";
import './style.scss';

const SetNicknameDialog = observer(() => {
  const { profileStore } = useStore();
  const {
    isOpenedModal,
    nicknameInputValue,
    onTextChange,
    onSaveNickname,
    hasError,
    errorMessage,
    closeModal,
  } = profileStore;

  return (
    <Dialog
      className="dialog-modal game-options nickname"
      open={isOpenedModal}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Nickname</DialogTitle>
      <DialogContent className="game-options-body">
        <FormCol>  
          <FormRow className="game-type-formrow nickname">
            <FormControl component="fieldset">
              <TextField
                helperText={
                  errorMessage || "Allowed characters: A-Z, a-z, 0-9, _ (Max 30 characters)"
                }
                error={hasError}
                type="text"
                label="Type here your nickname"
                className="nickname set-bet"
                onChange={onTextChange}
                value={nicknameInputValue}
              />
            </FormControl>
          </FormRow>
        </FormCol>

      </DialogContent>
      <DialogActions className="actions">
        <Button onClick={closeModal} color="primary">
          Cancel
        </Button>
        <CustomButton disabled={hasError} onClick={onSaveNickname} color="primary">
          Save
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
});

export default SetNicknameDialog;
