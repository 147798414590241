import { Tab, Tabs } from '@material-ui/core';
import { velas } from 'App/config';
import CustomButton from 'components/CustomButton';
import { Leaderboard } from 'components/Leaderboard';
import { Loader } from 'components/Loader';
import { LoaderLight } from 'components/LoaderLight';
import { SmallCounter } from 'components/SmallCounter';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { SearchModel } from 'models/SearchModel';
import { NewGameDialog } from 'pages/Game/components/NewGameDialog';
import React, { useEffect, useRef, useState } from 'react';
import { GamesTabs } from 'stores/TabsStore';
import { useAccount } from 'wagmi';
import { AllGamesList } from './All';
import { MyGamesList } from './My';
import './style.scss';


export const Games = observer(() => {
  const [isFocused, setIsFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 600px)').matches);
  const store = useStore();
  const { gamesStore, profileStore, tabsStore, gameStore, blockTimestampStore } = store;
  const [newGameDialogOpen, setNewGameDialogOpen] = useState(false);
  const { isConnected, chain } = useAccount();
  const searchModel = useRef(new SearchModel()).current;

  useEffect(() => {
    gamesStore.setSearchModel(searchModel);
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 600px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    gameStore.currentGame?.startPollingGameDetails();
    blockTimestampStore.startFetchingBlockTimestamp();
    gamesStore.stopPolling();
    gamesStore.startPolling();

    return () => {
      gamesStore.stopPolling();
    }
  })

  const isWrongNetwork = chain?.id !== velas.id;
  const onNewGameDlgClosed = () => setNewGameDialogOpen(false);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: GamesTabs) => tabsStore.setActiveTab(newValue)
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setIsFocused(false);
    gamesStore.searchModel?.setSearchQuery('');
  };

  if (gamesStore.isLoading) return <Loader show={true} text="Loading games..." />;

  return (
    <div className='main-container'>
      <div className='body'>
        <div className='actions'>
          {isConnected && !isWrongNetwork && (
            <div className="group-btns">
              <CustomButton onClick={() => setNewGameDialogOpen(true)}>{isMobile ? "Create" : "Create Game"}</CustomButton>
              <div className="input-wrapper-search">
                <button
                  className={`icon-search ${isFocused ? 'active' : ''}`}
                  onClick={handleFocus}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 48 48"><defs><mask id="ipTSearch0"><g fill="none" stroke="#edcdad" strokeLinejoin="round" strokeWidth={6}><path fill="#555" d="M21 38c9.389 0 17-7.611 17-17S30.389 4 21 4S4 11.611 4 21s7.611 17 17 17Z"></path><path strokeLinecap="round" d="M26.657 14.343A7.98 7.98 0 0 0 21 12a7.98 7.98 0 0 0-5.657 2.343m17.879 18.879l8.485 8.485"></path></g></mask></defs><path fill="#fff" d="M0 0h48v48H0z" mask="url(#ipTSearch0)"></path></svg>
                </button>
                <input
                  placeholder="Search.."
                  className="input-search"
                  name="text"
                  type="text"
                  value={gamesStore.searchModel?.searchQuery}
                  onChange={(e) => gamesStore.searchModel?.setSearchQuery(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          )}
        </div>

        <Tabs className='tabs' value={tabsStore.activeTab} onChange={handleTabChange} variant="scrollable">
          <Tab value={GamesTabs.ROOMS} label={
            <>
              <span>Rooms</span>
              <SmallCounter text={gamesStore.gamesToJoinList.length} />
            </>
          } />
          <Tab value={GamesTabs.MY_ROOMS} label={
            <>
              <span>My Rooms</span>
              <SmallCounter text={gamesStore.totalMyRoomsCount} />
            </>
            }
          />
          <Tab value={GamesTabs.LEADERBOARD} label="Leaderboard" />
        </Tabs>

        {tabsStore.activeTab === GamesTabs.ROOMS && (<AllGamesList />)}
        {tabsStore.activeTab === GamesTabs.MY_ROOMS && (<MyGamesList />)}
        {tabsStore.activeTab === GamesTabs.LEADERBOARD && (<Leaderboard />)}
      </div>
      <NewGameDialog
        open={newGameDialogOpen}
        onClose={onNewGameDlgClosed}
      />
      <LoaderLight show={profileStore.isLoading} />
    </div>
  );
});