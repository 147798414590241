import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { WeeklyLeaderboardList } from 'components/WeeklyLeaderboardList';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { getEndOfWeekInfo } from 'helpers';
import '../../pages/Games/style.scss';
import "./style.scss";


const WeeklyDialog = observer(() => {
  const { leaderboardStore } = useStore();
  const { formattedEndOfWeek, timeLeftString } = getEndOfWeekInfo();
  if (!leaderboardStore.weeklyModalIsOpened) {
    return null;
  }
  return (
    <Dialog
      className="dialog-modal weekly-dialog"
      open={leaderboardStore.weeklyModalIsOpened}
      onClose={leaderboardStore.closeWeeklyModal}
      aria-labelledby="empty-dialog-title"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="weekly-dialog-title" className='weekly-dialog-title'>
        <span>Weekly Leaderboard</span>
        <span className='weekly-dialog-subtitle'>Ends {formattedEndOfWeek} ({timeLeftString})</span>
      </DialogTitle>
      <DialogContent>
        <WeeklyLeaderboardList />
      </DialogContent>
      <DialogActions>
        <Button onClick={leaderboardStore.closeWeeklyModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default WeeklyDialog;
