import { ButtonProps, IconButton, Button as MaterialButton, Tooltip } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';

const Button = ({
    children,
    ...props
}: ButtonProps & {tooltipContent?: string}) => {
  return (props.tooltipContent) ? (
    <Tooltip title={props.tooltipContent}>
      <MaterialButton {...props}>
        <div className='button-content'>{children}</div>
        <IconButton size="small" style={{ filter: 'none' }} className="info">
          <FaInfoCircle fontSize="small" />
        </IconButton>
      </MaterialButton>
    </Tooltip>
  ) : (
    <MaterialButton {...props}>
      {children}
    </MaterialButton>
  )
};

export default Button;
