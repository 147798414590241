import { action, computed, makeObservable, observable } from "mobx";
import { Game } from "models/Game";
import { GameRaw, HexAddress } from "types";
import { RootStore } from "./RootStore";


export class GameFinishedModalStore {
  @observable isOpen = false;
  @observable content = '';
  @observable buttonName = '';
  @observable type: 'win' | 'loose' | 'draw' | 'quit' | undefined;
  @observable showDismiss = true;
  @observable onSubmit: (() => void) | undefined;
  constructor(private appState: RootStore) {
    makeObservable(this);
  }

  @action.bound
  onClose(): void {
    this.content = '';
    this.buttonName = '';
    this.onSubmit = undefined;
    this.isOpen = false;
  }

  @action.bound
  openModal(): void {
    if (!this.appState.gameStore.iAmGameParticipant) {
      return;
    }
    // Do not open modal if the user is not connected
    if (!this.appState.isConnected) {
      return;
    }
    if (this.appState.gameStore.currentGame?.isAborted) {
      this.isOpen = true;
      return this.openAbortedModal();
    }
    if (this.appState.gameStore.currentGame?.opponentRunOutOfTime) {
      this.isOpen = true;
      return this.openTimeoutWinModal();
    }
    if (this.appState.gameStore.currentGame?.winnerIsMe && this.appState.gameStore.currentGame?.playersHaveNotRunOutOfTimes) {
      this.isOpen = true;
      return this.openWinModal();
    }
    if (this.appState.gameStore.currentGame?.isDraft) {
      this.isOpen = true;
      return this.openDrawModal();
    }
    if (this.appState.gameStore.currentGame?.iHaveLostGame && !this.appState.gameStore.currentGame?.meRunOutOfTime) {
      this.isOpen = true;
      return this.openLoseModal();
    }
    if (this.appState.gameStore.currentGame?.iHaveLostGame && this.appState.gameStore.currentGame?.meRunOutOfTime) {
      this.isOpen = true;
      return this.openLoseModal('Time’s up, you lose!');
    }
  }

  @action.bound
  openAbortedModal(): void {
    this.type = 'quit';
    this.content = 'Game is aborted!';
    this.buttonName = "back to games";
    this.onSubmit = () => this.appState.navigate('/');
    this.showDismiss = false;
  }

  @action.bound
  openTimeoutWinModal(): void {
    this.type = 'win';
    this.content = 'Congratulations, you win!';   
    if (this.appState?.gameStore?.currentGame?.hasBet && !this.appState?.gameStore?.currentGame.winningsWasClaimed) {
      this.onSubmit = this.appState.gameStore.currentGame.checkAndEndGameWithWin;
      this.buttonName = "Claim";
    }
  }

  @action.bound
  openWinModal(text?: string): void {
    this.type = 'win';
    this.content = text || 'Congratulations, you win!';   
    if (this.appState?.gameStore?.currentGame?.hasBet && !this.appState?.gameStore?.currentGame.winningsWasClaimed) {
      this.onSubmit = this.appState.gameStore.currentGame.claimBets;
      this.buttonName = "Claim";
    } else {
      this.onSubmit = undefined;
      this.buttonName = "";
    }
  }

  @action.bound
  openDrawModal(): void {
    this.type = 'draw';
    this.content = 'Game Is TIE';   
    if (this.appState.gameStore?.currentGame?.claimBetAvailable) {
      this.onSubmit = this.appState.gameStore.currentGame.claimOwnBet;
      this.buttonName = "Claim";
    }
  }

  @action.bound
  openLoseModal(text?: string): void {
    this.type = 'loose';
    this.content = text || "Oops, you lose";
  }
}

export class GameStore {
  @observable currentGameId: string | null = null;
  @observable _currentGame: GameRaw | null = null;
  @observable _isLoading = true;
  gameFinishedModal: GameFinishedModalStore;
  lastPlayer2States = observable.map<Game['id'], HexAddress>();
 
  constructor(private appStore: RootStore) {
    makeObservable(this);
    this.gameFinishedModal = new GameFinishedModalStore(appStore);
    this.loadLastPlayer2States();
  }

  @computed
  get currentGame() {
    return this.appStore.gamesStore.allGamesList.find(game => game.id === this.currentGameId);
  }

  @computed
  get iAmGameParticipant() {
    return [this.currentGame?.player1, this.currentGame?.player2].includes(this.appStore.myAddress);
  }

  @computed
  get player1Rating() {
    if (!this.currentGame?.player1) {
      return null;
    }
    return this.appStore.leaderboardStore.getPlayerScore(this.currentGame.player1)
  }

  @computed
  get player2Rating() {
    if (!this.currentGame?.player2) {
      return null;
    }
    return this.appStore.leaderboardStore.getPlayerScore(this.currentGame.player2)
  }

  @computed
  get lastStep() {
    return this.currentGame?.step;
  }

  @computed
  private get player1LastMoveBigInt() {
    return BigInt.call(this, (0));
  }

  @computed
  private get player2LastMoveBigInt() {
    return BigInt.call(this, (0));
  }

  @computed
  get player1LastMoveTimestamp() {
    return Number(this.player1LastMoveBigInt.toString());
  }

  @computed
  get player2LastMoveTimestamp() {
    return Number(this.player2LastMoveBigInt.toString());
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get turn() {
    // const currentPlayerKey = `player${this.game?.currentPlayer}`;
    // //@ts-ignores
    // const currentPlayerAddress = this.game?.currentPlayerKey as string;
    // if (this.appStore.myAddress === currentPlayerAddress) {
    //   return
    // }
    return this.currentGame?.currentPlayer;
  }

  @computed
  get isMyTurn() {
    const currentPlayerKey = `player${this.currentGame?.currentPlayer}`;
    //@ts-ignore
    const currentPlayerAddress = this.currentGame?.[currentPlayerKey] as string;
    return this.appStore.myAddress === currentPlayerAddress;
  }

  set isLoading(val: boolean) {
    this._isLoading = val;
  }

  @action.bound
  setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  @action.bound
  saveLastPlayer2States() {
    const serializedMap = JSON.stringify(
      Array.from(this.lastPlayer2States.entries())
    );
    localStorage.setItem('lastPlayer2States', serializedMap);
  }

  @action.bound
  loadLastPlayer2States() {
    const serializedMap = localStorage.getItem('lastPlayer2States');
    if (serializedMap) {
      this.lastPlayer2States = observable.map<Game['id'], HexAddress>(JSON.parse(serializedMap));
    }
  }

}