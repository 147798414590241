type Color = string;
type IconName = string;

export type SvgIconProps = {
  height?: number;
  width?: number;
  secondaryColor?: Color;
  className?: string;
  name: IconName;
  stroke?: string;
} & (
    | { height: number; width: number; size?: number; fill?: string; background?: string; }
    | { height?: number; width?: number; size?: number; fill?: string; background?: string; }
  );

export const icons = (props: SvgIconProps) => (
  {

    lock: (
      //@ts-ignore
      // <svg width={props.width} height={props.height} id="Layer_1" style={{enableBackground:'new 0 0 30 30'}} version="1.1" viewBox="0 0 30 30" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      //   <path d="M24,27H6c-1.105,0-2-0.895-2-2V13c0-1.105,0.895-2,2-2h18c1.105,0,2,0.895,2,2v12C26,26.105,25.105,27,24,27z"/>
      //   //@ts-ignore
      //   <path d="M9,12  V9c0-3.314,2.686-6,6-6h0c3.314,0,6,2.686,6,6v3" style={{fill:"none", stroke: "#000000", strokeWidth:2, strokeLinecap:'round', strokeLinejoin: 'round', strokeMiterlimit:10 }}/>
      // </svg>
      <svg width={props.width} height={props.height} viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_638_717)">
        <g filter="url(#filter0_d_638_717)">
        <path d="M15.3933 7.73808H14.295V6.63977C14.295 5.18331 13.7165 3.78651 12.6866 2.75664C11.6567 1.72677 10.2599 1.14819 8.80346 1.14819C7.347 1.14819 5.9502 1.72677 4.92033 2.75664C3.89046 3.78651 3.31188 5.18331 3.31188 6.63977V7.73808H2.21357C1.92228 7.73808 1.64292 7.8538 1.43695 8.05977C1.23097 8.26574 1.11526 8.5451 1.11526 8.8364V17.3593C1.11303 17.9729 1.28263 18.5748 1.60485 19.097C1.92707 19.6191 2.38904 20.0406 2.93846 20.3138L8.30922 22.9937C8.46189 23.073 8.63141 23.1144 8.80346 23.1144C8.97551 23.1144 9.14503 23.073 9.2977 22.9937L14.6685 20.3138C15.2179 20.0406 15.6798 19.6191 16.0021 19.097C16.3243 18.5748 16.4939 17.9729 16.4917 17.3593V8.8364C16.4917 8.5451 16.3759 8.26574 16.17 8.05977C15.964 7.8538 15.6846 7.73808 15.3933 7.73808ZM9.90177 15.4263C9.90177 15.7176 9.78606 15.9969 9.58008 16.2029C9.37411 16.4089 9.09475 16.5246 8.80346 16.5246C8.51217 16.5246 8.23281 16.4089 8.02683 16.2029C7.82086 15.9969 7.70514 15.7176 7.70514 15.4263V13.2297C7.70514 12.9384 7.82086 12.659 8.02683 12.453C8.23281 12.2471 8.51217 12.1313 8.80346 12.1313C9.09475 12.1313 9.37411 12.2471 9.58008 12.453C9.78606 12.659 9.90177 12.9384 9.90177 13.2297V15.4263ZM12.0984 7.73808H5.50851V6.63977C5.50851 5.76589 5.85566 4.92781 6.47358 4.30989C7.0915 3.69197 7.92958 3.34482 8.80346 3.34482C9.67733 3.34482 10.5154 3.69197 11.1333 4.30989C11.7513 4.92781 12.0984 5.76589 12.0984 6.63977V7.73808Z" fill="url(#paint0_linear_638_717)"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_638_717" x="1.11523" y="1.14819" width="15.3765" height="23.529" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.56272"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.686275 0 0 0 0 0.0745098 0 0 0 0 0.619608 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_638_717"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_638_717" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_638_717" x1="7.04958" y1="5.6847" x2="4.07614" y2="19.7598" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#B39CB3"/>
        </linearGradient>
        <clipPath id="clip0_638_717">
        <rect width="16.5239" height="24.0974" fill="white" transform="translate(0.476074 0.689453)"/>
        </clipPath>
        </defs>
        </svg>

    ),
    
    downloadArrow: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
          d="M5.47 9.07C5.3752 9.00057 5.29583 8.91223 5.23689 8.81057C5.17795 8.70891 5.14074 8.59614 5.12759 8.47937C5.11444 8.3626 5.12565 8.24437 5.1605 8.13215C5.19535 8.01993 5.25309 7.91615 5.33008 7.82737C5.40707 7.7386 5.50162 7.66675 5.60778 7.61636C5.71394 7.56598 5.82939 7.53815 5.94685 7.53464C6.0643 7.53112 6.18121 7.552 6.29019 7.59596C6.39917 7.63991 6.49785 7.70598 6.58 7.79L8 9V1C8 0.734784 8.10536 0.48043 8.29289 0.292893C8.48043 0.105357 8.73478 0 9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1V9L11.42 7.77C11.5022 7.68598 11.6008 7.61991 11.7098 7.57596C11.8188 7.532 11.9357 7.51112 12.0532 7.51464C12.1706 7.51815 12.2861 7.54598 12.3922 7.59636C12.4984 7.64675 12.5929 7.7186 12.6699 7.80737C12.7469 7.89615 12.8046 7.99993 12.8395 8.11215C12.8744 8.22437 12.8856 8.3426 12.8724 8.45936C12.8593 8.57613 12.822 8.68891 12.7631 8.79057C12.7042 8.89223 12.6248 8.98057 12.53 9.05L9.53 11.63C9.49897 11.6536 9.46544 11.6737 9.43 11.69C9.38338 11.7226 9.33302 11.7494 9.28 11.77H9.13H8.97H8.81H8.66C8.60698 11.7494 8.55662 11.7226 8.51 11.69C8.47456 11.6737 8.44102 11.6536 8.41 11.63L5.47 9.07ZM17 10C16.7348 10 16.4804 10.1054 16.2929 10.2929C16.1054 10.4804 16 10.7348 16 11V14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15H3C2.73478 15 2.48043 14.8946 2.29289 14.7071C2.10536 14.5196 2 14.2652 2 14V11C2 10.7348 1.89464 10.4804 1.70711 10.2929C1.51957 10.1054 1.26522 10 1 10C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11V14C0 14.7956 0.316071 15.5587 0.87868 16.1213C1.44129 16.6839 2.20435 17 3 17H15C15.7956 17 16.5587 16.6839 17.1213 16.1213C17.6839 15.5587 18 14.7956 18 14V11C18 10.7348 17.8946 10.4804 17.7071 10.2929C17.5196 10.1054 17.2652 10 17 10Z"
          fill="#0560FD"/>
      </svg>
    ),

    trophy: (
      <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_753_750)">
<path d="M6.80212 10.8232L1.7793 20.2864L6.34047 19.865L8.54806 23.879L13.5709 14.4158L6.80212 10.8232Z" fill="#F04343"/>
<path d="M16.1975 10.8232L21.2203 20.2864L16.6591 19.865L14.4515 23.879L9.42871 14.4158L16.1975 10.8232Z" fill="#FF6161"/>
<path d="M11.4999 17.6648C16.1349 17.6648 19.8924 13.9074 19.8924 9.27235C19.8924 4.63732 16.1349 0.879883 11.4999 0.879883C6.86486 0.879883 3.10742 4.63732 3.10742 9.27235C3.10742 13.9074 6.86486 17.6648 11.4999 17.6648Z" fill="#FFB63C"/>
<path d="M4.48879 5.73029C4.26253 5.2412 6.48342 2.71405 6.90274 3.02086C7.32206 3.32767 6.62412 3.4079 5.78852 4.37556C4.95293 5.34321 4.71506 6.21937 4.48879 5.73029Z" fill="white"/>
<path d="M11.4995 15.262C8.19602 15.262 5.50879 12.574 5.50879 9.27124C5.50879 5.96848 8.19602 3.28125 11.4995 3.28125C14.803 3.28125 17.4902 5.9692 17.4902 9.27196C17.4902 12.5747 14.803 15.262 11.4995 15.262Z" fill="#FFCA6C"/>
<path d="M16.002 8.26489H13.0883L12.1872 5.49352C12.0715 5.13758 11.5674 5.13758 11.4516 5.49352L10.5513 8.26489H7.63758C7.26293 8.26489 7.10689 8.74452 7.41035 8.96456L9.76751 10.6774L8.86722 13.4488C8.75144 13.8047 9.15917 14.101 9.46262 13.881L11.8198 12.1681L14.177 13.881C14.4804 14.101 14.8881 13.8047 14.7724 13.4488L13.8721 10.6774L16.2292 8.96456C16.532 8.74452 16.3766 8.26489 16.002 8.26489Z" fill="#EC9922"/>
<path d="M15.6817 7.94555H12.7679L11.8669 5.17419C11.7512 4.81824 11.2471 4.81824 11.1313 5.17419L10.231 7.94555H7.31727C6.94262 7.94555 6.78658 8.42518 7.09004 8.64522L9.4472 10.3581L8.54691 13.1294C8.43113 13.4854 8.83885 13.7817 9.14231 13.5616L11.4995 11.8488L13.8566 13.5616C14.1601 13.7817 14.5678 13.4854 14.452 13.1294L13.5518 10.3581L15.9089 8.64522C16.2117 8.42518 16.0563 7.94555 15.6817 7.94555Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_753_750">
<rect width="23" height="23" fill="white" transform="translate(0 0.879883)"/>
</clipPath>
</defs>
</svg>

    ),
    trophy2: (
<svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_752_41502)">
<path d="M66.4305 15.9393C66.3762 28.522 60.9797 37.67 48.4484 38.8013C48.3912 38.8012 48.3455 38.7668 48.3455 38.7096C48.294 38.1005 48.0581 35.3158 48.0732 35.4938C48.0618 35.4481 48.119 35.4024 48.1762 35.4025C58.7894 34.4219 62.9596 26.6697 63.0205 15.9254C63.0205 15.9139 63.0205 15.8796 62.9862 15.8567C62.952 15.7994 62.9062 15.7993 62.8948 15.7993C54.503 15.7583 54.6556 15.8696 54.6558 15.7134C54.6996 12.6745 54.572 12.3833 54.7496 12.3834L62.7484 12.3892C64.9113 12.3907 66.4319 14.0125 66.4305 15.9393Z" fill="#FFB63C"/>
<path d="M64.4497 15.9376C64.4006 30.2759 57.4685 37.2016 48.333 38.5605C48.1281 35.5526 47.9597 35.4021 48.1751 35.4022C58.7882 34.4217 62.9585 26.6694 63.0193 15.9251C63.0193 15.9137 63.0194 15.8793 62.985 15.8564C62.9508 15.7991 62.905 15.799 62.8937 15.799C52.3423 15.756 52.6747 15.8666 52.6748 15.7117L52.6771 12.4962C52.6773 12.2703 52.529 12.4399 60.7676 12.3875C61.6373 12.3881 62.5411 12.6634 63.2044 13.2475C64.0165 13.9118 64.4505 14.8962 64.4497 15.9376Z" fill="#FFCA6C"/>
<path d="M24.9757 38.6146L24.9642 38.6947C24.9642 38.7519 24.9183 38.7863 24.8498 38.7862C12.4273 37.6467 6.937 28.5702 6.90034 15.8985C6.90168 14.0246 8.39286 12.352 10.5991 12.3536C18.8489 12.418 18.6895 12.2449 18.6893 12.474C18.6425 15.6732 18.7741 15.7695 18.5954 15.7694C9.98568 15.7632 10.4249 15.7066 10.3563 15.8208C10.3106 15.8436 10.3105 15.878 10.3105 15.8894C10.3733 26.6094 14.5058 34.3901 25.1383 35.3878C25.3275 35.388 25.1866 35.5168 24.9757 38.6146Z" fill="#FFB63C"/>
<path d="M24.9764 38.6139C13.3148 36.8365 8.50321 27.7915 8.46881 15.8988C8.47017 14.0001 9.97672 12.3524 12.1675 12.354C20.3642 12.4179 20.2579 12.2403 20.2578 12.4744C20.2101 15.7349 20.3516 15.7699 20.1523 15.7698C9.8757 15.7624 10.4254 15.706 10.3569 15.82C10.3112 15.8429 10.3111 15.8773 10.3111 15.8887C10.374 26.6086 14.5064 34.3893 25.1389 35.3871C25.3281 35.3872 25.1873 35.5161 24.9764 38.6139Z" fill="#FFCA6C"/>
<path d="M44.0955 57.4138L29.1865 57.4031C30.6412 53.4734 31.9657 50.0864 32.9123 47.0298C32.9242 47.018 32.9242 47.0298 32.9242 47.0298C33.4329 45.3539 33.8473 43.7724 34.0962 42.2499L39.2075 42.2536C39.4897 43.7294 39.9135 45.2995 40.4435 46.9879C41.4093 50.0223 42.7053 53.4585 44.0955 57.4138Z" fill="#FFCA6C"/>
<path d="M39.3717 42.9716C39.3205 42.7355 39.2513 42.4856 39.2069 42.2542L34.0956 42.2505C34.0574 42.4846 33.9913 42.7308 33.9454 42.9677L39.3717 42.9716Z" fill="#ED8D28"/>
<path d="M30.4123 54.1133C30.0164 55.1715 29.6093 56.2586 29.1852 57.4039L44.0942 57.4146C43.6921 56.2707 43.3024 55.1821 42.9219 54.1223L30.4123 54.1133Z" fill="#ED8D28"/>
<path d="M55.0729 59.9239L55.0672 67.9391L18.2019 67.9127L18.2076 59.8974C18.2092 57.7135 19.9811 55.9559 22.1531 55.9575L51.133 55.9783C53.2695 55.9798 55.0745 57.7179 55.0729 59.9239Z" fill="#613941"/>
<path d="M52.9022 58.1511L52.8978 64.1714C52.897 65.2732 52.0032 66.1657 50.9014 66.165L18.2032 66.1415L18.2077 59.8969C18.2092 57.7129 19.9812 55.9554 22.1531 55.9569L51.133 55.9777C51.558 55.978 51.9711 56.0492 52.3606 56.1793C52.7026 56.7462 52.9027 57.4309 52.9022 58.1511Z" fill="#804F59"/>
<path d="M24.9609 38.6925C24.9609 38.7514 24.9136 38.7868 24.8545 38.7868C23.9693 38.7036 23.1195 38.5848 22.3051 38.4189C21.3765 37.0241 20.3777 35.1751 19.9473 34.2031C21.446 34.7944 23.1691 35.2088 25.1403 35.3873C25.3333 35.3874 25.1889 35.5318 24.9609 38.6925Z" fill="#ED8D28"/>
<path d="M53.3662 34.2262C52.6569 35.7367 51.865 37.1526 50.9905 38.4504C50.1758 38.6033 49.3259 38.7208 48.4523 38.8028C48.2646 38.8027 48.3879 38.7478 48.077 35.4972C48.0033 35.2024 49.9998 35.5488 53.3662 34.2262Z" fill="#ED8D28"/>
<path d="M57.4417 15.8017C54.837 15.7669 54.6557 15.8724 54.6558 15.7172C54.7012 12.6726 54.5689 12.3883 54.7527 12.3884L57.4087 12.3904C57.4624 13.5518 57.4635 15.1208 57.4417 15.8017Z" fill="#ED8D28"/>
<path d="M40.4439 46.9884C38.1054 47.706 35.5098 47.7599 32.9246 47.0302C33.4333 45.3543 33.8477 43.7728 34.0966 42.2503L39.2079 42.254C39.4901 43.7298 39.914 45.2999 40.4439 46.9884Z" fill="#ED8D28"/>
<path d="M36.6437 45.3068C30.5882 45.3024 25.2086 40.7184 21.8258 33.6333C18.0672 25.8026 17.1934 15.9228 18.8937 7.15381L54.4604 7.17935C57.9776 25.4636 49.6059 45.3161 36.6437 45.3068Z" fill="#FFB63C"/>
<path d="M18.6864 15.6925C18.6863 15.8472 18.5087 15.7419 15.9123 15.7731C15.8633 14.9803 15.8988 13.1934 15.9384 12.3617L18.5944 12.3636C18.778 12.3637 18.6453 12.6432 18.6864 15.6925Z" fill="#ED8D28"/>
<path d="M50.3042 8.68886C50.2926 24.9554 41.9491 38.1231 31.6793 38.1157C28.0553 38.1131 24.6687 36.47 21.8259 33.6348C18.0673 25.8041 17.1934 15.9243 18.8937 7.1553L50.2817 7.17784C50.2931 7.67368 50.3046 8.18134 50.3042 8.68886Z" fill="#FFCA6C"/>
<path d="M56.3254 6.393C55.8292 6.87667 55.1562 7.18311 54.4007 7.18257L18.952 7.15711C17.9015 7.15636 16.9812 6.55366 16.5449 5.67983C16.5214 5.64451 16.4978 5.60905 16.486 5.57359C15.6451 3.67626 17.0607 1.72574 18.9559 1.7271C56.3915 1.80306 54.4926 1.62859 55.2898 1.89483C57.1895 2.55054 57.7335 4.98736 56.3254 6.393Z" fill="#ED8D28"/>
<path d="M51.947 5.70541L16.5454 5.68C16.5219 5.64468 16.4983 5.60922 16.4865 5.57375C15.6456 3.67643 17.0612 1.7259 18.9564 1.72726L54.3696 1.75269C55.2669 3.49407 54.046 5.70691 51.947 5.70541Z" fill="#FFB63C"/>
<path d="M51.418 1.74995C51.0184 2.76649 50.0063 3.43689 48.9026 3.43609L16.4405 3.41279C16.785 2.50569 17.7684 1.72579 18.9559 1.72664L51.418 1.74995Z" fill="#FFCA6C"/>
<path d="M57.6859 69.3218L57.6845 71.3167C57.6843 71.6354 57.4244 71.8831 57.1175 71.8829L15.7344 71.8531C15.4274 71.8529 15.1798 71.6049 15.18 71.2861L15.1814 69.2913C15.182 68.5358 15.7844 67.9223 16.5399 67.9229L56.3177 67.9514C57.0731 67.952 57.6865 68.5663 57.6859 69.3218Z" fill="#613941"/>
<path d="M56.0468 67.952L56.0454 69.9352C56.0452 70.254 55.7971 70.5017 55.4784 70.5014L15.1812 70.4725L15.182 69.2921C15.1825 68.5366 15.785 67.9231 16.5405 67.9237L56.0468 67.952Z" fill="#804F59"/>
<path d="M39.2604 14.4113L40.6257 17.1828C40.8849 17.7089 41.3865 18.0739 41.9669 18.1586L45.0239 18.6051C46.4856 18.8186 47.068 20.6154 46.0095 21.6457L43.7956 23.8006C43.3753 24.2097 43.1832 24.7995 43.2819 25.3777L43.802 28.4231C44.0506 29.8791 42.5218 30.9882 41.2148 30.2999L38.4812 28.8603C37.9623 28.587 37.342 28.5865 36.8226 28.8591L34.087 30.2948C32.779 30.9812 31.2518 29.8699 31.5025 28.4142L32.0269 25.3696C32.1265 24.7915 31.9353 24.2015 31.5155 23.7917L29.3047 21.6337C28.2477 20.6019 28.8327 18.8059 30.2946 18.5945L33.3523 18.1525C33.9328 18.0685 34.4349 17.7043 34.6949 17.1785L36.0641 14.409C36.7188 13.0849 38.6076 13.0863 39.2604 14.4113Z" fill="#ED8D28"/>
<path d="M37.987 12.8742L39.3523 15.6456C39.6115 16.1718 40.1131 16.5368 40.6935 16.6215L43.7505 17.068C45.2121 17.2815 45.7945 19.0783 44.736 20.1085L42.5222 22.2635C42.1018 22.6726 41.9097 23.2623 42.0085 23.8405L42.5286 26.8859C42.7772 28.342 41.2483 29.4511 39.9414 28.7628L37.2078 27.3232C36.6888 27.0499 36.0686 27.0494 35.5492 27.322L32.8135 28.7577C31.5056 29.4441 29.9783 28.3328 30.2291 26.8771L30.7535 23.8325C30.8531 23.2544 30.6618 22.6644 30.2421 22.2546L28.0313 20.0965C26.9743 19.0647 27.5593 17.2688 29.0212 17.0574L32.0789 16.6154C32.6594 16.5314 33.1615 16.1672 33.4214 15.6414L34.7907 12.8719C35.4454 11.5478 37.3342 11.5492 37.987 12.8742Z" fill="white"/>
<g opacity="0.5">
<path d="M46.1403 59.6072L46.1377 63.2005C46.1373 63.7516 45.6902 64.1981 45.1391 64.1977L28.1326 64.1855C27.5815 64.1851 27.1351 63.738 27.1355 63.1869L27.138 59.5936C27.1384 59.0425 27.5855 58.596 28.1366 58.5964L45.1431 58.6086C45.6943 58.609 46.1407 59.0561 46.1403 59.6072Z" fill="white"/>
</g>
<g opacity="0.49">
<path d="M20.6673 63.6647C20.6691 63.7862 20.6538 64.6404 20.5468 64.7905C20.443 64.9071 20.2397 64.7957 20.1198 64.6304C19.9163 64.2977 19.8936 63.6842 19.9108 63.3389C19.9091 63.0931 20.1084 62.4916 20.3988 62.6226C20.7029 62.8254 20.6722 63.1545 20.6673 63.6647Z" fill="white"/>
<path d="M21.6198 59.4479C21.5678 59.8158 21.2948 61.3289 21.0262 61.5834C20.7652 61.8294 20.2952 61.6911 20.0802 61.3952C19.6742 60.8351 19.7955 59.6839 19.9238 59.0384C20.0078 58.5824 20.6334 57.3913 21.2704 57.5642C21.9947 57.7182 21.7009 58.8188 21.6198 59.4479Z" fill="white"/>
</g>
<path d="M21.8314 9.71009C22.1856 10.0987 22.2941 10.9904 22.3241 11.7171C22.4098 13.7908 22.2857 15.8689 21.7436 17.3933C21.4761 18.1454 20.9369 18.1898 20.5904 17.2027C19.4208 13.8699 20.7614 8.53636 21.8314 9.71009Z" fill="white"/>
<path d="M21.7179 20.1892C21.9702 21.2906 22.1269 22.4771 22.0353 23.5253C21.9427 24.5866 21.2057 24.1409 20.8892 21.8747C20.5909 19.7385 21.2656 18.2143 21.7179 20.1892Z" fill="white"/>
<path d="M40.5761 4.45799C48.6478 3.56373 49.8416 5.22141 52.6718 2.76547C52.0631 3.62471 51.1395 4.31472 50.0635 4.58207C48.7366 4.98617 42.4549 4.66895 40.5761 4.45799Z" fill="white"/>
<path d="M65.9527 54.031C65.3958 53.0912 64.5261 52.2524 63.4338 51.4632C62.3374 52.7922 61.373 53.9429 59.3195 55.8556C63.2635 46.983 51.3141 42.4941 51.8499 34.5424C54.5621 33.4765 57.1114 31.7136 59.2623 29.7136C54.8598 39.6399 70.3567 44.0927 65.9527 54.031Z" fill="#FF6161"/>
<path d="M13.9869 57.1764C12.3983 55.5625 11.1124 54.0641 10.0503 52.625C9.06285 53.2674 8.09055 54.0871 7.42869 55.0779C3.43112 44.981 19.0981 41.1544 15.1005 31.0574C17.3337 32.6318 19.5847 33.9894 22.1633 34.8404C23.8917 43.7132 10.2199 47.6595 13.9869 57.1764Z" fill="#FF6161"/>
<path d="M59.3188 55.856C63.2628 46.9834 51.3134 42.4945 51.8492 34.5428C53.4118 33.9287 54.8995 33.1014 56.3337 32.0871C55.6413 35.1264 55.9007 38.2137 56.9126 40.9633C58.8067 46.0352 62.0236 46.8506 63.1137 51.8663C62.0644 53.1377 60.8163 54.4612 59.3188 55.856Z" fill="#F04343"/>
<path d="M13.9862 57.1774C12.3977 55.5635 11.1117 54.0652 10.0496 52.6261C9.648 52.8874 9.26913 53.163 8.93804 53.4436C10.4999 48.9903 15.0127 49.2967 16.9409 39.302C17.4601 36.4676 17.7963 35.1169 20.4019 34.2016C20.4139 34.2028 20.4271 34.1921 20.4403 34.1813C21.0072 34.4329 21.5777 34.6484 22.1627 34.8415C23.891 43.7143 10.2192 47.6605 13.9862 57.1774Z" fill="#F04343"/>
</g>
<defs>
<clipPath id="clip0_752_41502">
<rect width="73" height="73" fill="white"/>
</clipPath>
</defs>
</svg>
    )

  }
)
