import { computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";


export class Member {
  @observable address = '';
  constructor(
    private appStore: RootStore,
    address: string,
  ) {
    this.address = address;
    makeObservable(this);
  }

  @computed
  get stats() {
    return this.appStore.leaderboardStore.stats[this.address];
  }

  @computed
  get statsTableData() {
    return this.appStore.leaderboardStore.getPlayerStatsProfilePage(this.address);
  }

  @computed 
  get nickname() {
    return this.appStore.profileStore.nicknamesMap[this.address];
  }

  @computed
  get games() {
    return this.appStore.gamesStore.getPlayersGames(this.address);
  }

  @computed
  get currentGames() { 
    return this.games.filter(game => (game.player1 === this.address || game.player2 === this.address) && !game.hasWinner);
  }

  @computed
  get completedGames() {
    return this.games.filter(game => game.isFinished || game.hasWinner);
  }

  @computed
  get myGamesCount() {
    return this.games.length;
  }

}