import { useEffect } from "react";

import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { Game } from "models/Game";
import { PlayerContainer } from "pages/Game";
import { GameBoard, Player } from "utils/GameBoard";
import './style.scss';
import { CellButton, CellContainer, GameContainer } from "./styled";


export const GameBoardPreview = observer(({gameId, preview}: {gameId: string; preview?: boolean}) => {
  const { gamesStore, GAME_LENGTH, navigate } = useStore();
  const game = gamesStore.getGameById(gameId);
  const { temporaryStep } = game || {} as Game;
  const gameLength = game?.gameLength || GAME_LENGTH;
  const gameBoard: GameBoard = GameBoard.getInstance(gameLength);

  useEffect(() => {
    if (game) {
      gameBoard.setCells(game?.board);      
      const realTurn = game.turn === 2 ? 1 : 2;
      //@ts-ignore
      gameBoard.turn = realTurn;
    }
  }, [game, game?.board]);


  if (!game) {
    return null;
  }

  const buttons: any[] = [];

  for (let i = 0; i < gameLength; i = i + 1) {
    for (let j = 0; j < gameLength; j = j + 1) {
      let className = "btn-free";
      if (game?.board[i][j] === Player.WHITE) {
        className = `btn-selected btn-selected-white player-${Player.WHITE}`;
      } else if (game?.board[i][j] === Player.BLACK) {
        className = `btn-selected btn-selected-black player-${Player.BLACK}`;
      }
      if (i === temporaryStep?.row && j === temporaryStep.column) {
        className += " active"
      }

      // If game has a winner, highlight the winner row's cells
      if (game && game?.winnerIndex !== Player.NONE && game.winnerCells.length >= 5) {
        game.winnerCells.forEach(winnerCell => {
          if (winnerCell.row === i && winnerCell.column === j) {
            className += " btn-winner-cell";
          }
        });
      }

      // Every time Highlight last move:
      const { lastPlayedCell } = game;
      if (
        lastPlayedCell &&
        lastPlayedCell.row === i &&
        lastPlayedCell.column === j &&
        game?.board[i][j] !== Player.NONE
      ) {
        className += " btn-last-played";
      } else {
        className = className.replace(" btn-last-played", "");
      }

      buttons.push(
        <CellContainer className="game-preview" length={gameLength} key={`btn-${i}-${j}`}>
          <CellButton
            isActive={i === temporaryStep?.row && j === temporaryStep?.column}
            turn={game.turn}
            className={className}
            // onClick={game.clickCell}
            data-row={i}
            data-column={j}
            disabled={true}
          />
        </CellContainer>
      );
    }
  }
  return (
    <div className="game-preview-container">
      <div className="game-preview-container-cover" onClick={() => navigate(`/game/${gameId}`)}></div>
      <GameContainer className={`game-board ${preview ? 'game-preview': ''}`} length={gameLength}>{buttons}</GameContainer>
      <PlayerContainer
        gameId={game.id}
        timeLeft={game.opponentTimeLeft}
        playerAddress={game.opponentAddress} 
        playerShortAddress={game.opponentAddressShort}
        playerIndex={2}
        totalGameTime={game?.timeLimitSeconds}
        small
      />
    </div>
  );
});
