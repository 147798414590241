import Table from "components/Table";
import { useStore } from "hooks/useStore";
import React, { useState } from "react";
import Player from '../../assets/imgs/player.png';


import { Address } from "components/Address";
import "./style.scss";


export const WeeklyLeaderboardList = () => {
  const { leaderboardStore, gamesStore } = useStore();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  
  const leadersArray = leaderboardStore.playersWithScores.map((data, index) => {
    return {
      "Rank": data.ranking,
      Player: (
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <Address address={data.player} value={data.player} />
        </div>
      ),
      
      Score: data.score,
    }
  });

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      header: "Rank",
      flex: 0.3,
    },
    {
      header: (
        <>
          <img src={Player} alt="players" width={24}/>
          <p>Players</p>
        </>
      ),
      flex: 4,
    },
    {
      header: (
        <span className="container">Trophies</span>
      ),
      flex: 1,
    },    
  ];

  return (
    <div className='leaderboard-table'>
      <div className='leaderboard-list'>
        {leadersArray.length > 0 ?
          <Table data={leadersArray} columns={columns} />
          :
          <p className='container empty-state'>No result...</p>
        }
      </div>
    </div>
  );
};