import { Tooltip } from '@material-ui/core';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { velas } from 'App/config';
import HexAvatar from 'components/Avatar';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAccount, useBalance, usePublicClient } from 'wagmi';
import LogoDarkShort from '../../assets/imgs/logo-dark-short.svg';
import LogoDark from '../../assets/imgs/logo-dark.svg';
import LogoShort from '../../assets/imgs/logo-short.svg';
import Logo from '../../assets/imgs/logo.svg';
import '../../pages/Games/style.scss';
import './style.scss';

export const Header = observer(() => {
  const store = useStore();
  const { address, isConnected, chain } = useAccount();
  const publicClient = usePublicClient();
  const { themeStore, profileStore } = store;
  const location = useLocation();
  console.log('location.pathname', location.pathname);
  const id = location.pathname.split('/')?.[1] || 'main-page';

  const isWrongNetwork = chain?.id !== velas.id;
  const isDarkTheme = themeStore.themeId === 'dark';

  const { refetch } = useBalance({
    address,
  });

  // Refresh ConnectButton balance
  useEffect(() => {
    if (publicClient && address) {
      const handleBlock = async () => {
        await refetch(); // Refresh balance on every new block
      };

      const unsubscribe = publicClient.watchBlocks({
        onBlock: handleBlock, // Block listener
      });

      return () => unsubscribe();
    }
  }, [publicClient, address, refetch]);

  return (
    <div className='header' id={id}>
       <img
        className={`logo desktop-logo`}
        src={isDarkTheme ? LogoDark : Logo}
        alt="logo"
        onClick={() => store.navigate('/')}
      />
      <img
        className={`logo mobile-logo`}
        src={isDarkTheme ? LogoDarkShort : LogoShort}
        alt="logo-short"
        onClick={() => store.navigate('/')}
      />
      <div className="right-side">
        <ConnectButton showBalance={true} accountStatus={'address'}/>
        {isConnected && !isWrongNetwork && (
          <Link className="address-value" to={`/member/${address}`}>
            {profileStore.hasOnlineGames && (
              <Tooltip title={`You are currently playing in ${profileStore.onlineGames.length} game${profileStore.onlineGames.length > 1 ? 's' : ''}`}>
                <span className='notification-round'></span>
              </Tooltip>
            )}
            <HexAvatar address={address} width={35} height={35} />
          </Link>
          // <Button onClick={profileStore.openModal} className="btn-profile">
          //   <img src={Action} alt='player' width={20} />
          //   @name
          // </Button>
        )}
      </div>
    </div>
  );
});
