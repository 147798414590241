import { readContract } from '@wagmi/core';
import { config, gomokuContract } from "App/config";
import { ethers } from "ethers";
import { createGameLink2, fetchWithTimeout } from 'helpers';
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { Game } from "models/Game";
import { SearchModel } from 'models/SearchModel';
import { emptyAddress, GameRaw, GameStatus } from "types";
import { abi } from "web3/contract/abi";
import { RootStore } from "./RootStore";



export class GamesStore {
  @observable isLoading = true;
  @observable _gamesList: Game[] = [];
  @observable currentGameId: string | null = null;
  @observable fetchInterval: number | null = null;
  @observable hoveredGameId: string | null = null;
  @observable searchModel?: SearchModel | undefined = undefined;

  @observable isLoadingPolling = true;
  currentInterval = 2500; // Initial interval
  maxInterval = 10000; 
  fetchTimeout = 2500; 

  constructor(private appStore: RootStore) {
    makeObservable(this);
    // this.startPolling();
    // this.execBatchRequest();
  }

  @action.bound
  setSearchModel(model: SearchModel) {
    this.searchModel = model;
  }

  @action.bound
  startPolling() {
    console.log('polling - startPolling')
    this.stopPolling();
    if (!this.fetchInterval) {
      this.fetchInterval = setInterval(async () => {
        try {
          console.log('fetchAllGames', this.currentInterval)
          const startTime = Date.now();

          // Wrap fetchAllGames with a timeout
          const resultFetched = await fetchWithTimeout(this.fetchAllGames(), this.fetchTimeout);

          const elapsed = Date.now() - startTime;
          if (!resultFetched && elapsed > this.currentInterval) {
            this.currentInterval = Math.min(this.currentInterval + 500, this.maxInterval);
            console.warn(`Polling interval increased to: ${this.currentInterval}ms`);
            this.restartPolling();
          }
        } catch (error) {
          console.error("Polling error or timeout:", error);
          this.currentInterval = Math.min(this.currentInterval + 500, this.maxInterval);
          this.restartPolling();
        }
      }, this.currentInterval);
    }
  }

  @action.bound
  restartPolling() {
    this.stopPolling();
    this.startPolling();
  }

  @action.bound
  stopPolling() {
    console.log('polling - stopPolling')
    if (this.fetchInterval) {
      clearInterval(this.fetchInterval);
      this.fetchInterval = null;
    }
  }

  @computed
  get allGamesList(): Game[] {
    return [...this._gamesList].reverse();
  }

  @computed
  get gamesList(): Game[] {
    const filtered = this._gamesList.filter(g => g.status !== GameStatus.CANCELED);
    filtered.sort((a, b) => {
      if (a.player2 !== emptyAddress && b.player2 === emptyAddress) {
        return 1;
      }
      if (a.player2 === emptyAddress && b.player2 !== emptyAddress) {
        return -1;
      }
      return 0;
    });

    return [...filtered].reverse();
  }

  @computed
  get gamesToJoinList() {
    return this.gamesList.filter(game => game.isPlayer2Absent);
  }

  @computed
  get gamesAlreadyJoinedList() {
    return this.gamesList.filter(game => !game.isPlayer2Absent);
  }

  @computed
  get totalRoomsCount() {
    return this.gamesList.length;
  }

  @computed
  get myGamesList() {
    return this.gamesList.filter(game => game.isMyGame || game.takingPart);
  }

  @computed
  get myGamesListWhereITakePart() {
    return this.gamesList.filter(game => game.player1 === this.appStore.myAddress || game.player2 === this.appStore.myAddress);
  }

  @computed
  get myGamesListWithoutOpponent() {
    return this.myGamesList
  }

  @computed
  get totalMyRoomsCount() {
    return this.myGamesList.length;
  }

  @computed
  get hoveredGame() {
    return this.gamesList.find(game => game.id === this.hoveredGameId);
  }

  @action.bound
  async fetchAllGames() {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'listAllGames',
    });
    // console.log('resultresult',{ gomokuContract, result })
    const gamesRaw = result as GameRaw[];
    
    runInAction(() => {
      this._gamesList = gamesRaw.map(raw => new Game(this.appStore, raw));
      if (this.isLoading) {
        this.isLoading = false;
      }
      this.detectPlayer2Changes();
    });
    return result;
  }

  detectPlayer2Changes() {
    this.myGamesListWithoutOpponent.forEach((game) => {
      const previousPlayer2 = this.appStore.gameStore.lastPlayer2States.get(game.id) || emptyAddress;
      const currentPlayer2 = game.player2;

      // If `player2` was empty and now has a valid address, notify
      if (
        previousPlayer2 === emptyAddress &&
        currentPlayer2 &&
        currentPlayer2 !== emptyAddress
      ) {
        const joinedPlayer = this.appStore.profileStore.nicknamesMap[currentPlayer2] || currentPlayer2;
        // this.appStore.notify.showInfo(`Player ${joinedPlayer} has joined your game ${createGameLink(game.id)} <a href="sss">sss</a>`, {autoClose: 200000});
        this.appStore.notify.showInfo(createGameLink2(game.id, joinedPlayer), {autoClose: 200000})
      }

      // Update the lastPlayer2 state
      this.appStore.gameStore.lastPlayer2States.set(game.id, currentPlayer2);

      this.appStore.gameStore.saveLastPlayer2States();
    });
  }

  getGameById(id: string): Game | undefined {
    return this._gamesList.find(game => game.id === id);
  }

  getPlayersGames(address: string) {
    if (!address) return [];
    return this.gamesList.filter(game => game.player1 === address || game.player2 === address);
  }

  @action.bound
  handleMouseEnter(gameId: string) {
    return (event: React.MouseEvent<HTMLDivElement>) => {
      // Do whatever you need with gameId
      console.log("Hovered over game with ID:", gameId);
      this.hoveredGameId = gameId;

    };
  }

  @action.bound
  handleMouseLeave() {
    this.hoveredGameId = null;
  }

  @action.bound
  async execBatchRequest() {
    // const provider = new ethers.JsonRpcProvider('https://evmexplorer.velas.com/rpc');
    const contractInterface = new ethers.Interface(abi);
    const listAllGamesData = contractInterface.encodeFunctionData("listAllGames", []);
    const listAllNicknamesData = contractInterface.encodeFunctionData("getAllNicknames", []);
    const batchRequest = [
      {
        jsonrpc: "2.0",
        id: 1,
        method: "eth_call",
        params: [
          {
            to: gomokuContract, // Contract address
            data: listAllGamesData, // Encoded function call
          },
          "latest", // Block parameter
        ],
      },
      {
        jsonrpc: "2.0",
        id: 2,
        method: "eth_call",
        params: [
          {
            to: gomokuContract, // Contract address
            data: listAllNicknamesData, // Encoded function call
          },
          "latest", // Block parameter
        ],
      },
    ];
    const response = await fetch("https://evmexplorer.velas.com/rpc", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(batchRequest),
    });
    const results = await response.json();

    if (response.ok) {
      // Decode results
      const gamesRaw = contractInterface.decodeFunctionResult("listAllGames", results[0].result);
      const nicknames = contractInterface.decodeFunctionResult("getAllNicknames", results[1].result);
      if (gamesRaw?.length) {
        const games = gamesRaw[0] as GameRaw[];
        runInAction(() => {
          this._gamesList = games.map(raw => new Game(this.appStore, raw));
          if (this.isLoading) {
            this.isLoading = false;
          }
        });
      }

      console.log("Nicknames:", nicknames);
    } else {
      console.error("Error:", results);
    }
  
    // const contract = new ethers.Contract(gomokuContract, abi, signer);
    // const amount = ethers.parseUnits("0", 18);;
    // const data = contract.interface.encodeFunctionData("listAllGames", []);
    // const fn  =await contract.getFunction('listAllGames')
   

    // const batchRequest = [
    //   {
    //     jsonrpc: "2.0",
    //     method: "listAllGames",
    //     to:gomokuContract,
    //     params: [],
    //     id: 1,
    //   },
    //   {
    //     jsonrpc: "2.0",
    //     method: "getAllNicknames",
    //     params: [],
    //     id: 2,
    //   },
    //   // {
    //   //   jsonrpc: "2.0",
    //   //   method: "eth_getTransactionCount",
    //   //   params: ["0x742d35Cc6634C0532925a3b844Bc454e4438f44e", "latest"],
    //   //   id: 3,
    //   // },
    // ];
    // fetch("https://evmexplorer.velas.com/rpc", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(batchRequest),
    // })
    //   .then((response) => response.json())
    //   .then((data) => console.log({data}))
    //   .catch((error) => console.error("Error:", error));

  }
}