import { readContract } from "@wagmi/core";
import { config, gomokuContract } from "App/config";
import { isBigInt } from "helpers";
import { action, makeObservable, observable } from "mobx";
import { abi } from "web3/contract/abi";



export class BlockTimestampStore {
  @observable blockTimestamp: bigint | undefined;
  @observable intervalId: number | undefined;
  @observable lastFetchTime = 0;
  @observable localClockInterval: number | undefined;
  constructor() {
    makeObservable(this);
    this.startFetchingBlockTimestamp();
  }

  @action.bound
  setBlockTimesamp(value: bigint) {
    this.blockTimestamp = value;
  }

  @action.bound
  async getBlockTimestamp(): Promise<bigint> {
    const result = await readContract(config, {
      abi,
      address: gomokuContract,
      functionName: 'getCurrentBlockTimestamp',
      args: [],
    });
    // if (isBigInt(result)) { 
    //   this.setBlockTimesamp(result as bigint);
      
    // }
    return result as bigint;
  }

  @action.bound
  startFetchingBlockTimestamp(intervalMs: number = 10000, incrementStepMs: number = 1000) {
    console.log('startFetchingBlockTimestamp')
    // Clear any existing interval to avoid multiple instances
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.localClockInterval) {
      clearInterval(this.localClockInterval);
    }
    const incrementStepSeconds = BigInt(incrementStepMs / 1000);

    // Set interval to fetch block timestamp periodically
    this.intervalId = setInterval(async () => {
      console.log('polling - setBlockTimesamp')
      const newBlockTimestamp = await this.getBlockTimestamp();
      console.log('get newBlockTimestamp', intervalMs)
      if (isBigInt(newBlockTimestamp)) { 
          this.setBlockTimesamp(newBlockTimestamp);
          this.lastFetchTime = Date.now();
        }
    }, intervalMs);

    // Increment the timestamp locally every `incrementStepMs`
    this.localClockInterval = setInterval(() => {
      const now = Date.now();
      const timeSinceLastFetch = BigInt(Math.floor((now - this.lastFetchTime) / 1000));
      if (this.blockTimestamp) {
        this.blockTimestamp += timeSinceLastFetch > incrementStepSeconds ? incrementStepSeconds : timeSinceLastFetch;
      }
    }, incrementStepMs)
  }

  @action.bound
  stopFetchingBlockTimestamp() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      clearInterval(this.localClockInterval);
      this.intervalId = undefined;
    }
  }


}