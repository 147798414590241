import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SetNicknameDialog from "components/SetNicknameModal";
import { useStore } from "hooks/useStore";
import { observer } from "mobx-react";
import { Profile } from "pages/Profile";
import { StoreProvider } from "provider/StoreContext";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ConnectionListener from "services/ConnectionListener";
import { GamesTabs } from "stores/TabsStore";
import { useAccount } from "wagmi";
import { Header } from '../components/Header';
import { Hiw } from '../components/Hiw';
import Marquee from "../components/Marquee";
import NotFound from "../components/NotFound";
import { Theme } from '../components/Theme';
import BoardComponent from '../pages/Game';
import { Games } from '../pages/Games'; // Import your Games component
import ProtectedRoute from "./ProtectedEoute";
import { AppContainer } from "./styled";
import './styles.scss';

const theme = createTheme({
  typography: {
     // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 10
  }
});


const App: React.FC = observer(() => {
  const { profileStore, gameStore, themeStore, tabsStore } = useStore();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const { address } = useAccount();
  const store = useStore();

  useEffect(() => {
    if (address) {
      profileStore.setMyAddress(address);

      // If the user is on a profile page and the address changes, update the URL
      const currentPath = window.location.pathname;
      if (currentPath.startsWith("/member/")) {
        const [, , currentAddress] = currentPath.split("/");
        if (currentAddress !== address) {
          store.navigate(`/member/${address}`, { replace: true });
        }
      }
    }
  }, [address, profileStore, store]);

  useEffect(() => {
    tabsStore.setActiveTab(GamesTabs.ROOMS);

    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const gameHasPlayer2 = !gameStore.currentGame?.isPlayer2Absent || false;
  const isMyGame = gameStore.currentGame?.isMyGame || false;
  const gameRouteCondition = gameHasPlayer2 || isMyGame;
  return (
    <BrowserRouter>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <ConnectionListener />
          <AppContainer className={`theme-mode ${themeStore.themeId}-theme app-container`}>
            {isOffline && (
              <div className="offline-notification">
                Connection lost. Waiting for network...
              </div>
            )}
            <Header />
            <SetNicknameDialog />
            <Routes>
              <Route path="/" element={<Games />} />
              <Route element={<ProtectedRoute condition={gameRouteCondition} redirectTo="/" />} >
                <Route element={<BoardComponent />} path="/game/:id"/>
              </Route>
              <Route path="/member/:address" element={<Profile />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Marquee />
            <Hiw />
            <Theme />
            {/* <Profile /> */}
          </AppContainer>
        </ThemeProvider>
      </StoreProvider>
    </BrowserRouter>
  );
});

export default App;
