
import { FC, useEffect } from "react";
import Clock from '../../assets/imgs/clock.png';
import Vlx from '../../assets/imgs/vlx.png';

import { Game } from "./components/Game";
import { GameFinishedDialog } from "./components/GameFinishedDialog";
import {
  BoardContainer,
  Content,
  GameContainer
} from "./styled";

import { CircularProgress } from '@material-ui/core';
import { Address } from 'components/Address';
import HexAvatar from 'components/Avatar';
import Button from "components/Button";
import CustomButton from 'components/CustomButton';
import { GameTimer } from 'components/GameTimer';
import { Loader } from 'components/Loader';
import { LoaderLight } from 'components/LoaderLight';
import NotFound from 'components/NotFound';
import { SvgIcon } from 'components/SvgIcon';
import { formatDate } from "helpers";
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { emptyAddress, PlayerContainerProps } from 'types';
import { GameBoard } from "utils/GameBoard";
import './styles.scss';

const BoardComponent: FC = observer(() => {
  const store = useStore();
  const { gameStore, blockTimestampStore, gamesStore, navigate, GAME_LENGTH } = store;
  const { currentGame } = gameStore;
  console.log({currentGame});
  const showGameLoader = currentGame?.showGameLoader || false;
  // Get a singleton instance of the GameBoard (creates an instance if it's first-time-call)
  const gameBoard: GameBoard = GameBoard.getInstance(gameStore.currentGame?.gameLength || GAME_LENGTH);
  // const [gameState, dispatchGameBoard] = useGameBoard(gameBoard);
  const { id } = useParams();
  const isOpponentAbsent = currentGame?.player2 === emptyAddress;

  useEffect(() => {

    gamesStore.stopPolling();
    if (!gameStore.currentGame?.hasWinner) {
      gameStore.currentGame?.stopPolling();
      blockTimestampStore.stopFetchingBlockTimestamp();
      gameStore.currentGame?.startPollingGameDetails();
      blockTimestampStore.startFetchingBlockTimestamp();
    } else {
      gameStore.currentGame?.stopPolling();
      blockTimestampStore.stopFetchingBlockTimestamp();
    }

    return () => {
      gameStore.currentGame?.stopPolling();
      blockTimestampStore.stopFetchingBlockTimestamp();
    }
  }, [gameStore.currentGame])


  useEffect(() => {
    // Check if a winner is set in the game details
    if (currentGame?.gameDetails?.winner && !gameStore.gameFinishedModal.isOpen) {
      gameStore.gameFinishedModal.openModal();
    }
    if (currentGame?.isAborted) {
      gameStore.gameFinishedModal.openModal();
    }

  }, [currentGame?.gameDetails.winner, currentGame?.hasDisplayedWinner]);

  useEffect(() => {
    if (id) {
      gameStore.currentGameId = id;
    }
    return () => {
      gameBoard.clearCells();
    }
  }, [id]);

  if (!gameStore.currentGame || !id) return <NotFound />;
  const { player1, player2 } = gameStore.currentGame;
  return (
    <BoardContainer className='board-container game-page'>
      <GameFinishedDialog />

      {/* {useMemo(() => {
        return ( */}
          <Content className='game-content'>
            <div className='actions'>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    currentGame?.clearGameFinishedModal();
                    console.log('vback')
                    navigate(-1);
                  }}
                  className='btn-back'
                  >
                  ⬅︎
                </Button>
                {currentGame?.showAbortGameButton && (
                  <Button
                    tooltipContent="You can abort the game if it hasn't started yet or if no moves have been made."
                    variant="contained"
                    color="primary"
                    onClick={currentGame?.openAbortConfirm}
                    >
                    Abort game
                  </Button>
                )}
                {currentGame?.showWinAndFinishedGameButton && currentGame?.hasBet && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={currentGame.checkAndEndGameWithWin}
                  >
                    Timeout Win
                  </Button>
                )}
                {currentGame?.claimBetAvailable && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={currentGame.claimOwnBet}
                  >
                    Claim Bet
                  </Button>
                )}
                {currentGame?.showWinButton && (
                  <CustomButton
                    variant="contained"
                    color="primary"
                    onClick={currentGame.claimBets}
                  >
                    Claim Win
                  </CustomButton>
                )}
                </div>
                {currentGame?.hasBet && (
                  <p>Total bet: {isOpponentAbsent ? `${currentGame?.betAmount} VLX` : `${currentGame?.betAmount * 2} VLX`}</p>
                )}

              <p>Room: {store.gameStore.currentGameId}</p>
            </div>
            <GameContainer className='game-field'>
              {/* {currentGame?.hasDisplayedWinner  && (
                <div className={`player-trophy ${currentGame.displayedWinner === player1 ? 'player-trophy-p1' : currentGame.displayedWinner === player2 ? 'player-trophy-p2' : ''}`}>
                  <SvgIcon name="trophy" width={40} height={40} />
                </div>
              )} */}
              <div className='players'>
                <PlayerContainer
                  gameId={id}
                  winner={gameStore.currentGame.winner} 
                  timeLeft={gameStore.currentGame.timeLeftPlayer1}
                  playerAddress={gameStore.currentGame?.player1}
                  playerShortAddress={gameStore.currentGame?.player1Short}
                  playerIndex={1}
                  totalGameTime={gameStore.currentGame?.timeLimitSeconds}
                  rating={gameStore.player1Rating}
                />
                <PlayerContainer
                  gameId={id}
                  winner={gameStore.currentGame.winner}  
                  timeLeft={gameStore.currentGame.timeLeftPlayer2}
                  playerAddress={gameStore.currentGame?.player2}
                  playerShortAddress={gameStore.currentGame?.player2Short}
                  playerIndex={2}
                  totalGameTime={gameStore.currentGame?.timeLimitSeconds}
                  rating={gameStore.player2Rating}
                />
              </div>
              <Game />
            </GameContainer>
            {gameStore.isLoading && (
              <Loader show={true} />
            )}
            <LoaderLight show={showGameLoader} />
            <div className="game-footer">
              {gameStore.currentGame?.gameStartedAt?.toString() !== '0' && (
                <span>Game started at {formatDate(gameStore.currentGame?.gameStartedAt)}</span>
              )}
            </div>
          </Content>
        {/* );
      }, [gameState, dispatchGameBoard, id])} */}
    </BoardContainer>
  );
});
export default BoardComponent;


export const PlayerContainer = observer(({ gameId, small, winner, rating, timeLeft, playerAddress, playerShortAddress, playerIndex, totalGameTime }: PlayerContainerProps) => {
  const store = useStore();
  const { gamesStore } = store;
  const game = gamesStore.getGameById(gameId);

  if (!playerAddress) {
    return null;
  }
  const isOpponentAbsent = playerAddress === emptyAddress;
  const isCurrentTurn = game?.turn === playerIndex && !game.hasWinner;
  const isWinner = winner === playerAddress;

  const progress = ((timeLeft ?? 0) / totalGameTime) * 100;

  return (
    <div className={`player-container ${small ? 'small' : ''} player ${isCurrentTurn ? 'active-turn' : ''} ${isWinner ? 'player-winner' : ''}`}>
      <div className="chip"></div>
      {isOpponentAbsent
        ? (
          <>
            <HexAvatar width={55} height={55} />
          </>
        ) : (
          <div className="progress-container" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            {isCurrentTurn && game?.gameStartedAt > BigInt(0) && (
              <CircularProgress
                variant="static"
                value={progress}
                size={60}
                thickness={5}
                style={{ position: 'absolute', zIndex: 1 }}
              />
            )}
            {isWinner && (
              <div
                className={`player-trophy test ${playerIndex === 1 ? 'player-trophy-p1' : 'player-trophy-p2'}`}
                style={{ position: 'absolute', zIndex: 2 }}
              >
                <SvgIcon name="trophy" width={40} height={40} />
              </div>
            )}
            <HexAvatar address={playerAddress} width={55} height={55} />
            
          </div>
        )
      }
      <div className="player-col">
        <div className='player-info'>
          {isOpponentAbsent
            ? (
              <>
                <span>Waiting for opponent...</span>
              </>
            ) : (
              <>
                <Address value={playerShortAddress} />
                {rating!== undefined && rating !== null && !isNaN(rating) ? (
                  <span className='rating'>({rating})</span>
                ) : null}
              </>
            )
          }
        </div>
        <div className='time'>
          <div className='row'>
            {isOpponentAbsent ? (
              null
            ) : (
              game?.hasBet && (
                <>
                  <img src={Vlx} alt="vlx" />
                  <p>Bet: {game?.betAmount}{" "}VLX</p>
                </>
              )
            )}
          </div>
          <div className='row'>
            <img src={Clock} alt="timing"/>
            {timeLeft !== undefined && timeLeft !== null && (
              <GameTimer player={playerIndex} timeLeft={timeLeft} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
})