import { useCallback } from "react";
import { toast, ToastOptions } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const useNotifications = () => {
    const showError = useCallback((message: string | React.ReactNode) => {
        toast.error(message);
    }, []);

    const showSuccess = useCallback((message: string | React.ReactNode) => {
        toast.success(message);
    }, []);

    const showInfo = useCallback((message: string | React.ReactNode, options?: ToastOptions<unknown>) => {
        toast(message, options);
    }, []);

    const showWarning = useCallback((message: string | React.ReactNode) => {
        toast.warn(message);
    }, []);

    // General error handler, including contract errors
    const handleError = useCallback((error: any) => {
        let errorMessage = "An unexpected error occurred!";

        if (error && typeof error === 'object') {
            // Check for common error structures
            if (error.message) {
                const parsedMessage = parseContractErrorMessage(error.message);
                errorMessage = parsedMessage || error.message;
            } else if (error.reason) {
                errorMessage = error.reason;
            } else if (error.data && error.data.message) {
                errorMessage = error.data.message;
            }
        } else if (typeof error === 'string') {
            errorMessage = error;
        }

        showError(errorMessage);
    }, [showError]);

    const parseContractErrorMessage = (message: string): string | null => {
        // Define all known contract errors
        const errorMessages = [
            "The game is over",
            "You are not a participant in this game.",
            "Coordinates are out of range.",
            "This cell is already occupied.",
            "Player 2 has not yet joined.",
            "You must send the correct bet amount.",
            "Only the winner can claim the reward.",
            "This game already has two players.",
            "You cannot join your own game.",
            "It's not your turn.",
            "User rejected the request."
        ];

        for (let errorMessage of errorMessages) {
            if (message.includes(errorMessage)) {
                return errorMessage;
            }
        }
        return null;
    };

    return {
        showError,
        showSuccess,
        showInfo,
        showWarning,
        handleError,
    };
};

export default useNotifications;
