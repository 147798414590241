

const minutesTOms = (minutes: number) => {
  return minutes * 60 * 1000;
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Pad minutes and seconds with leading zeros
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

const fetchWithTimeout = async<T>(promise: Promise<T>, timeout: number): Promise<T | null> => {
  let timeoutHandle: number;
  const timeoutPromise = new Promise<null>((_, reject) => {
    timeoutHandle = setTimeout(() => reject(new Error("Fetch timeout")), timeout);
  });

  try {
    const result = await Promise.race([promise, timeoutPromise]);
    return result as T;
  } catch (error) {
    console.error("Fetch operation failed or timed out:", error);
    return null;
  } finally {
    clearTimeout(timeoutHandle!);
  }
}

export { fetchWithTimeout, formatTime, minutesTOms };

